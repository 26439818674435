<template>
  <div class="flex flex-1 flex-row overflow-y-auto">
    <BaseSidebar v-model="sidebarOpen" size-class="w-64">
      <template #header>Admin Panel</template>
      <template #default>
        <div v-for="(category, ix) in allowedCategories" :key="category.id">
          <BaseDivider v-if="ix > 0" class="my-2" />
          <BaseNavRadio v-model="selectedPage">
            <template #label>{{ category.title }}</template>
            <BaseNavRadioOption v-for="tab in category.tabs" :key="tab.id" :option="tab">
              {{ tab.title }}
            </BaseNavRadioOption>
          </BaseNavRadio>
        </div>
        <div class="pb-5"></div>
      </template>
    </BaseSidebar>
    <slot />
  </div>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { usePage } from '@inertiajs/vue3';

const sidebarOpen = ref(true);
const page = usePage();
const sidebarCategories = ref([
  {
    id: 'customers',
    title: 'Customers',
    permission: 'customer',
    tabs: [
      { id: 'teams', title: 'Teams', href: '/teams' },
      { id: 'users', title: 'Users', href: '/users' },
      { id: 'api-v2', title: 'API V2', href: '/api_keys_v2' }
    ]
  },
  {
    id: 'data',
    title: 'Data',
    permission: 'data',
    tabs: [
      { id: 'blockchains', title: 'Blockchains', href: '/blockchains' },
      { id: 'calendar', title: 'Calendar', href: '/calendar_events' },
      { id: 'coins', title: 'Coins', href: '/coins', isParent: true },
      { id: 'categories', title: 'Categories', href: '/categories', parent: 'coins' },
      { id: 'subcategories', title: 'Sub Categories', href: '/subcategories', parent: 'coins' },
      { id: 'unlock', title: 'Unlocks', href: '/unlock', parent: 'coins' },
      { id: 'finance_notes', title: 'Finance Notes', href: '/finance_notes', parent: 'coins' },
      { id: 'companies', title: 'Companies', href: '/companies', isParent: true },
      { id: 'company_categories', title: 'Categories', href: '/company_categories', parent: 'companies' },
      { id: 'recommended_feeds', title: 'Recommended Feeds', href: '/recommended_feeds' },
      { id: 'research', title: 'Research', href: '/research_publishers', isParent: true },
      { id: 'research_publishers', title: 'Publishers', href: '/research_publishers', parent: 'research' },
      { id: 'research_parsed_emails', title: 'Parsed Emails', href: '/research_parsed_emails', parent: 'research' },
      { id: 'research_raw_emails', title: 'Raw Emails', href: '/research_raw_emails', parent: 'research' },
      { id: 'recommended_companies', title: 'Recommended Search For Companies', href: '/recommended_filters' },
      { id: 'ccxt_exchanges', title: 'CCXT Exchanges', href: '/ccxt_exchanges' },
      // { id: 'presets', title: 'Presets', href: '/presets' },
      { id: 'manange-assets', title: 'Manage Assets', href: '/manage_assets' }
    ]
  },
  {
    id: 'developers',
    title: 'Developers',
    permission: 'developer',
    tabs: [
      { id: 'background_jobs', title: 'Background Jobs', href: '/background_jobs' },
      { id: 'roles', title: 'Roles', href: '/roles' },
      { id: 'telegram', title: 'Telegram', href: '/telegram_key_info' },
      { id: 'webhooks', title: 'Webhooks', href: '/webhooks' },
      { id: 'investments', title: 'Investments', href: '/investments' },
      { id: 'tooltips', title: 'Tooltips', href: '/tooltips' },
      { id: 'iframes', title: 'Iframe Integrations', href: '/iframes' },
      { id: 'api-permissions-v2', title: 'API V2 Permissions', href: '/api_permissions_v2' },
      { id: 'cache', title: 'Cache Reset', href: '/cache' },
      { id: 'tags-categories', title: 'Tag Categories', href: '/tag_categories' },
      { id: 'mobile-latest-updates', title: 'Mobile Latest Updates', href: '/mobile_latest_updates' }
    ]
  },
  {
    id: 'token_relations',
    title: 'TOKEN RELATIONS',
    permission: 'token_relations',
    tabs: [
      { id: 'page-configuration', title: 'Page Configurations', href: '/page_configurations' },
      { id: 'team-members', title: 'Token Team Members', href: '/token_members' }
    ]
  },
  {
    id: 'dataset_library',
    title: 'DATASET LIBRARY',
    permission: 'dataset_library',
    tabs: [{ id: 'dataset-library', title: 'Dataset Library', href: '/dataset_library' }]
  }
]);

const categoryTabs = computed(() => {
  let tabs = [];
  allowedCategories.value.map(cat => tabs.push(...cat.tabs));
  return tabs;
});

const store = useStore();

const allowedCategories = computed(() =>
  sidebarCategories.value.filter(
    category => store.getters.roles.includes('super_admin') || store.getters.roles.includes(category.permission)
  )
);

const pathname = ref(window.location.pathname);
const selectedPage = computed({
  get: () => {
    return categoryTabs.value.find(p => pathname.value.includes(`/admin${p.href}`)) || categoryTabs.value[0];
  },
  set: value => {
    pathname.value = `/admin${value.href}`;
  }
});

watch(
  selectedPage,
  () => {
    if (selectedPage.value && !page.url.includes(`/admin${selectedPage.value.href}`)) {
      router.visit(`/admin${selectedPage.value.href}`);
    }
  },
  { immediate: true }
);

watch(
  () => page.url,
  () => {
    if (page.url.includes('/admin') && pathname.value != page.url) pathname.value = page.url;
  }
);
</script>
